@import '_settings';
header {
    background-color: $header-background;
    color:$header-font-color;
    border-top:5px solid $header-border-color;
    border-radius: 0;
    box-shadow: 0 1px 0 rgba(0,0,0,.1);
    position: relative;
    z-index: 3333;
    min-height:84px;
    .top-bar {
        background-color: $header-background;
        .dropdown.menu > li > a {
            background-color: $header-background;
            color:$header-font-color;
            &:hover {            
                color:$header-font-color !important; 
                text-decoration: underline;
                &::after {
                    border-color:$header-font-color transparent transparent !important;
                }
            }
            &::after {
                border-color:$header-font-color transparent transparent !important;
            }
        }
    }    
    .branding {
        h1 {
            &.logo {
                max-width: 250px;
                text-align: center;
                img {
                    max-width:205px;
                    max-height: 60px;
                    width:auto !important;
                    height: auto !important;
                }                    
            }
        }
    }
}

.page-title{
  padding: 1rem 0px;
  background-color: white;  
  text-align: center;   
  h2{
    color: #4a4a4a;    
  }
}
.content{
    @include breakpoint(small only){
        padding: 2rem;
    }
    @include breakpoint(medium){
        margin-bottom: 2rem;
        min-height: 396px;
    }

}
ul.vertical.is-dropdown-submenu{
    box-shadow: 0px 1px 4px 0px
    rgba(3,3,3,0.3);    
    border: none;
    .is-submenu-item.is-dropdown-submenu-item {
        border-bottom: 1px solid white;
    }    
}
#logo{
    /* svg appears to required a width in pixels */
    width: 350px;
    max-width: 350px;
    max-height: 70px;
}
.branding-wrap{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
#account-menu{
    li{
        &:hover{
            background: transparent !important;
        }
        &:focus{
            background: transparent !important;
        }        
    }
}
