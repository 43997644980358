.flash {
    &.callout {
        background-color:#767676;
        color:#ffffff;
        &.alert {
            background-color:#C9252D;
        }
        &.success {
            background-color:#127F5C;
        }
        &.warning {
            background-color:#0F66D0;
        }
        .close-button {
            color: #fff;
        }
    }
}