.full-width-dashboard {
    .main-wrapper {
        display: flex;
        width:100%;
        min-height:calc(100vh - 89px - 50px);
        background: $wrapper-background;
        overflow: hidden;
        aside {
            flex: 0 0 250px;
            width:250px;
            position: relative;
            -webkit-box-shadow: 10px 0 10px -10px rgba(26,66,103,0.3);
            box-shadow: 10px 0 10px -10px rgba(26,66,103,0.3);
            background:$aside-background;
            min-height:calc(100vh - 89px - 50px - 30px);
            top:36px;
            &::before{
                content:'';
                display:block;
                width:100%;
                height:36px;
                position:absolute;
                top:-36px;
                left:0px;
                z-index:300;
                background:$aside-offset-bg;
            }
            &::after{
                content:'';
                display:block;
                width:100%;
                height:20px;
                position:absolute;
                top:0px;
                left:0px;
                z-index:300;
                background:$aside-offset-bg;
            }
            .branding {
                padding:30px 0;
                -webkit-box-shadow:inset 0 0 10px 0 rgba(26,66,103,0.3);
                box-shadow:inset 0 0 10px 0 rgba(26,66,103,0.3);
                // background:#f1f1f1;
                img.vendor-logo { 
                    width:auto !important;
                    height:auto !important;                              
                    max-height:50px;
                    max-width:90%;
                    margin:0px auto;
                    display: block;
                }
            }
            .menu {
                li {
                    border-bottom:1px solid #E9F5FF; 
                    font-size:1.2rem;
                    &:first-child {
                        border-top:1px solid #E9F5FF; 
                    }    
                    a {
                        padding:10px 20px;
                        font-family:$body-font-family;
                        color:$nav-color;
                        font-weight:400;
                        font-size:0.9rem;  
                        background-color: transparent;  
                        // border-radius: 0 40px 40px 0;
                        &:hover {
                            background-color: rgb(238,238,238);
                            color:$nav-dark; 
                            border-radius: 0px;
                        }          
                    }
                    div.is-drilldown {
                        display: none;
                    }
                    &.menu-text {
                        font-weight:500;
                        font-size:0.9rem; 
                        color:$nav-dark;
                    }
                    &.active {
                        > a {
                            background-color: rgb(238,238,238);
                            transition: background-color .4s;
                        } 
                        div.is-drilldown {
                            display: block;
                        }
                    }
                    ul.nested {
                        margin-left: 0px;
                        padding:0px;
                        li {
                            a {
                                padding-left:55px;  
                                &:hover {
                                    background-color: #e8f0fe;
                                    color:$nav-dark; 
                                    border-radius: 0px;
                                }                             
                            }
                            &.menu-text {
                                padding-left:55px;
                                &.fas {
                                    padding-left:30px;
                                    &:before {
                                        margin-right:10px;
                                    }            
                                }
                            }
                            &.active {
                                > a {
                                    background-color: #e8f0fe;
                                } 
                             }
                        }
                    }
                }
            }
            .fas {
                font-family:$body-font-family;
                &:before {
                    font-family: 'Font Awesome\ 5 Free';
                    font-weight: 600;
                    margin-right:20px;
                }            
            }
            &.collapsable {
                position:relative;                
                ul {
                    margin-top:20px;
                    ul {
                        margin-top:0px;
                    }
                }
                .toggle-menu {
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height:24px;
                    border-radius:50%;
                    padding:0px;
                    margin:0px;
                    position:absolute;
                    top: -18px;
                    right: -12px;
                    z-index:600;
                    color: $top-bar-icon-default;
                    background-color: $top-bar-icon-default-bg;
                    border:1px solid $top-bar-icon-default;
                    i {
                        font-size:11px;
                        &::before {
                            margin:0px;
                        }
                    } 
                    .open {
                        display: none;   
                        position: relative;
                        left: 2px;                 
                    }
                    .close {
                        display: block;

                    }
                }
            
            }
            &.collapsed-sidebar {
                flex: 0 0 15px;
                width:15px;
                > ul {
                    margin-top:50px;
                    display: none;
                }
                li {
                    a {
                        text-indent:-9999px;
                        position: relative;
                        padding:0px;
                        height:34px;
                        &::before {
                            position: absolute;
                            left: 20px;
                            top: 10px;
                            text-indent: 0px;
                        }
                    }
                }
                .toggle-menu { 
                    .close {
                        display: none;
                    }
                    .open {
                        display: block;                    
                    }
                }                
            }            
        }
        main {
            flex: 1;
            background: $main-background;
            border-radius: 0px;
            padding:0px 0px 10px 0px;
            overflow:inherit;
        }
    }    
}    

.flex-row {
    display: flex;
    &.options-right {
        
        justify-content: space-between;
    }
}

.table-note {
    font-style: italic;
    text-align:right;
}