header {
    .top-bar {
        .menu {
            li {
                &.button-vert {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
                a {
                    &.button {
                        &.small {
                            padding: 6px 12px;
                        }
                        &.hollow {
                            &.primary {
                                color:$top-bar-icon;           
                            }  
                            &.secondary {                                
                                color:$top-bar-icon-alt; 
                            }
                            &.second-alt {
                                color:$top-bar-icon-alt2;  
                            }                
                        }
                    }
                }
                &.has-left-sep {
                    border-left:1px solid $panel-border;
                    padding-left: 15px;
                    margin-left:5px;
                }
            }
        }        
        .icon-only-display {
            margin:0 15px;
            .has-icon {   
                text-indent: -9999px;
                position:relative;   
                display: block;
                margin: 0; 
                padding:0px !important;  
                width:44px;
                height:44px;
                .far, .fas {
                    position:absolute;
                    left:0px;
                    top:0px;
                    color:$top-bar-icon-default;
                    font-size: 20px;
                    height: 44px;
                    width:44px;
                    text-indent:0px; 
                    background-color:$top-bar-icon-default-bg;
                    border-radius: 50%;
                    display: flex;                
                    justify-content: center;
                    align-items: center;
                    &:before {
                        text-indent:0px;                  
                    }
                }
                &.primary {
                    .far, .fas {
                        color:$top-bar-icon;
                        background-color:$top-bar-icon-bg;
                    }                
                }  
                &.secondary {
                    .far, .fas {
                        color:$top-bar-icon-alt;
                        background-color:$top-bar-icon-bg-alt;
                    }  
                }
                &.second-alt {
                    .far, .fas {
                        color:$top-bar-icon-alt2;
                        background-color:$top-bar-icon-bg-alt2;
                    }  
                }                
                &.primary-icon {
                    .far, .fas {
                        color:$top-bar-icon;
                    }  
                }  
                &.secondary-icon {
                    .far, .fas {
                        color:$top-bar-icon-alt;
                    }  
                }                           
            }
        }
        .separator {
            width:1px;
            text-indent: -99999px;
            overflow: hidden;
            background-color: $panel-border;
            padding:0px;
            margin:0px 5px;
        }
        a {
            &.icon-spacing {
                &::before {
                    margin-right:5px;
                }
            }
        }
        .dropdown.menu {
            li.icon-only-display {
                a.has-icon {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .top-bar-left-inner {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            .branding {
                padding-right: 50px;
            }  
            .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
                display:none;
            }          
        }
        .nav-panel-display {
            min-width: 22rem;
            h2 {
                font-size:1.05rem;             
            }
            h3 {
                font-size:1rem;
            }
            p {
                font-size: 0.9rem;
            }
            .nav-panel-title {
                position: relative;
                border-bottom:1px solid $panel-border;
                h2 {
                    margin:0px;
                    padding:15px 100px 0 15px;                
                }
                p {
                    margin:0px;
                    padding:0px 100px 15px 15px;
                }
                .sign-out {
                    position: absolute;
                    top:15px;
                    right:15px;
                    .button {
                        max-width:100px;
                        padding:6px 12px;
                    }
                }
            }
            .nav-panel-body {
                padding:15px;
                ul {
                    margin:0px;
                    li {
                        list-style-type: none;
                    }
                }
            }
            &.quick-grid {
                width: 27.25rem;
                .nav-panel-body {
                    padding: 0px;
                    .panel-nav-grid {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
                        gap: 0px 0px;
                        border-top-width: 0px;
                        .item {
                            display:flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            border-right: $panel-border solid 1px;
                            border-bottom: $panel-border solid 1px;
                            background-color:$nav-panel-bg;                            
                            &:hover {
                                background-color:$nav-panel-bg-hover;
                                cursor: pointer;
                                * {
                                    background-color:$nav-panel-bg-hover;
                                }
                            }
                            .icon-holder {
                                color:$top-bar-icon-color;
                                font-size:2rem;
                                padding:1.2rem 0 0.5rem 0;
                            }
                            .destination {
                                color:$top-bar-icon-text;
                                font-weight:600;
                                font-size:0.9rem;
                                padding: 1rem 4px 1.15rem 4px;
                                text-align:center;
                            }
                        }
                    }
                }
            }
            &.search-panel {
                width: 75vw; 
                .nav-panel-body {
                    min-height:350px;
                    input {
                        max-width:100%;
                    }
                    .search-results-wrap {
                        padding:20px 0;
                        .search-type-wrap {
                            h3 {
                                padding:13px 0 0 0;
                                margin:0px;
                            }
                            .search-results {
                                padding:5px 0px;
                                border-bottom:1px solid $panel-border;
                                > span {
                                    padding:0 0 0 15px;
                                }
                            }
                            a {
                                padding:1rem 1rem;
                                color:$font-action;
                            }
                        }                        
                    }

                }         
            }
        }
    }
} 
