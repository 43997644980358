.panel {
    background:$panel-bg;
    margin:32px;
    padding:0px;
    border:1px solid $panel-border;  
    .panel-body {
        margin:0px;
        border-left:none;
        border-right:none;
    }
    .panel-title {
        margin:0px; 
        border:none; 
        h2, h3 {
            font-size:1.375rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }   
    .panel-bottom {
        margin:0px;
        border:none;
    }     
}
.panel-body {
    margin:32px;
    padding:16px 20px; 
    background:$panel-bg;
    border:1px solid $panel-border;
    &.has-tabs {
        padding-top:0px;
        .panel-tabs {
            position: relative;
            height:1px; 
            width:100%;
            .tab-buttons{
                position: absolute;
                bottom: 0px;
                left: 20px;
                margin: 0px;
            }           
        }
        .content-tab-group {
            padding-top:16px;
        }
    } 
    .header-wrap {
        margin-bottom:32px;
        h3 {
            margin-bottom:0px;
        }
    }   
    .grid-container {
        margin-left:0px;
        margin-right:0px;
        padding-left:0px;
        padding-right:0px;
    }
    form { 
        fieldset {
            padding:32px 0;
            margin:0px;
            legend {
                font-family: $header-font-color-family;
                font-weight:700;
                font-size:1.16525rem;
                color:$legend-color;
                padding:0 0 5px 0;
                margin:0px;
            }
            img {
                max-width:150px;            
                height:auto !important;
            }
        }
    }        
}
.panel-title {
    background:$panale-title-bg;
    margin:32px 32px 0px 32px;
    padding:16px 10px 16px 20px; 
    color:$header-font-color;
    border:1px solid $panel-border;
    border-bottom:none;
    * {
        padding:0px;
        margin:0px;
    }
    + .panel {
        margin-top:0px;
    }
    &.has-tabs {
        margin-bottom: 40px;
    }
}
.panel-bottom {
    margin:0px 32px 0px 32px;
    padding:10px 20px 10px 20px; 
    background-color:$panel-footer-bg;
    border:1px solid $panel-border;
    border-top:none;
}
.panel-separator {
    margin:3rem 32px;
    padding:0px;
    height:1px;
    background-color:$panel-sep;
    border:none;
}
.grid-panels {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px 40px; 
    margin:32px;
    .panel-wrap {
        background-color:$panel-bg;
        padding:16px 32px;
        border:1px solid $panel-border;
    }
}
.panel-bar {
    background:$panel-bar-bg;
    .panel-bar-inner {
        margin:0px 32px;
        padding:16px 32px; 
    }
}
.panel-card {
    .pc-content {
        display:flex;
        .pc-icon {
            width: 44px;
            height: 44px;
            font-size:2rem;
            margin-right: 20px;
            margin-bottom: 16px;
            border-radius: 50%;
            background-size: cover;
        }
    }
    .pc-footer {
        position:relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
            transform: rotate(-140deg);
            transform-origin: initial;
        }
    }
}
.panel {
    .data-display-wrapper {
        h3 {
            margin:0px;
            padding:0px 0 5px 0;
            border-bottom:1px solid $medium-gray;
            &:first-child {
                padding-top:16px;
            }
        }
        h3 + .grid-x {
            padding:24px 0 32px 0;
            margin:0px;
        }
    }
}
.filter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px 20px;
    label {
        color: $green;
    }
    .apply {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        input[type='submit'] {
            margin:11px 0 0 10px;
        }
        a {
            margin:11px 0 0 10px;
        }
    }  
}
.filter-grid-wrap {
    border:1px solid $green;
    box-shadow: 0px 0px 2px 0 $panel-shadow-color;
    padding:10px 15px;
    background:$light-green;
    .filter-grid {
        label {
            color:$dark-green;
        }
    }
    .accordion {
        background-color:transparent;
        .accordion-item {
            background-color:transparent;
            margin:0px;
            padding:0px;
            .accordion-title {
                background-color:transparent;
                margin:0px;
                padding-left:0px;
                border: none;                
                &.h3 {
                    font-size: 1.375rem;
                    color:$dark-green;
                }
            }
            .accordion-content {
                background-color:transparent;
                margin:0px;
                padding:0px;
                border: none;
            }
        }
    }

}
.page-split-grid {
    display: grid;
    grid-template-columns: 1fr 250px;
    gap: 20px 20px;
}

#flash-notice {
    margin:32px;
}