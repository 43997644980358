.menu {
    li {
        ul {
            &.nested {
                display: none;
            }
        }
        &.active {
            ul {
                &.nested {
                    display: flex;
                }
            }
        }
    }    
}
.dropdown {
    &.menu {
        li {
            padding-bottom: 5px;
            font-weight:400;
            a {

            }
            &.is-active {
                ul.menu {
                    border-top:3px solid $nav-accent-color;
                    background:$white;
                    box-shadow: 0 2px 5px rgba(0,0,0,.1);
                    text-align:left;
                    li {
                        padding:0px;
                        margin:0px;
                        line-height: 2em;
                        a {
                            padding:10px 15px;
                            margin:0px;
                            font-weight:400;
                            letter-spacing: 1px; 
                            color:$nav-color;
                            &:hover {
                                color:$black;
                                background-color: rgba(0,0,0,.03);
                            }                         
                        }
                        &.active {
                            a {
                                color:$black;
                            }
                        } 
                    }
                }
            }         
        }        

    }
}
.top-bar {
    ul {
        background-color: transparent;
        color:$nav-color;
        li {
            a {
                color:$nav-color;
                font-weight:400;
            }
        }

    }

}
.jump-menu {
    .dropdown {
        &.menu {
            li {
                &.menu-text {
                    font-weight: 500;
                }
                &.is-active {
                    .submenu {
                        border-top:3px solid $nav-accent-color;
                        background:$white;
                        box-shadow: 0 2px 5px rgba(0,0,0,.1);
                        text-align:left;
                        li {
                            padding:0px;
                            margin:0px;
                            line-height: 2em;
                            &.menu-text {
                                font-size: 1rem;
                                font-weight: 500;
                                padding:10px 20px 5px 10px;
                                background-color: rgba(238,238,238, 0.3);
                                &.fas {
                                    font-family:$body-font-family;
                                    &:before {
                                        font-family: 'Font Awesome\ 5 Free';
                                        font-weight: 600;
                                        margin-right:10px;
                                    }            
                                }
                            }                            
                            a {
                                padding:10px 20px;
                                font-family:$body-font-family;
                                color:$nav-color;
                                font-weight:400;
                                font-size:0.9rem;  
                                background-color: transparent;  
                                border-radius: 0.25rem;
                                &:hover {
                                    background-color: rgb(238,238,238);
                                    color:$nav-dark; 
                                    border-radius: 0px;
                                }                       
                            }
                            &.active {
                                a {
                                    color:$black;
                                }
                            } 
                        }
                    }
                }         
            }        
    
        }
    }
}


