
.table-heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
  .controls{
    display: flex;      
    button{
      margin-bottom: 0px;
    }
  }
}
table {
  margin:20px 0;
  thead {
    tr {
      th {
        border-top: 1px solid $table-border-color;
        border-bottom: 2px solid $table-border-color;
        background:$white;
      }
    }
  }
  tbody {

  }
  tr {
      th {
          font-size:1rem; 
          box-sizing: content-box;
      }
      td {
          font-size:1rem; 
          box-sizing: content-box;       
      }
  }
}

.results-filters {
  background-color: $light-gray;
  padding: 1.5rem 1rem 1rem 1rem;
  border: 1px solid $table-border;
  margin-top: 2rem;
  margin-bottom:2rem;
  .filters{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin:0px auto;
  }
  .field{
    &.apply{        
      @media all and (min-width: 1250px) {    
        margin-left: 0px;
      }
      input{
        margin-top: 0px; 
        margin-bottom: 0; 
        padding-top: 10px;               
      }
    }      
    label{
      color: $label-color;
      display: flex;
      flex-direction: column;
      margin: 0px 0.2rem;
      input, select{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;                     
        width: 100%;                
      }
    }      
  }

  &.reports {
    label {
      margin: 0px 0.2rem;
    }
    &.multi{      
      flex-direction: column;
      @media all and (min-width: 1250px) {    
        flex-direction: row;
      }
      .inner{
        display: flex;
        &:last-of-type{
          @media all and (min-width: 1250px) {    
            width: 100%;
          }
        }
      }
    }
  }  
  [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    margin: 0 0 0.9rem;
  }
}
.page-no {
  display: none;
}
.user-options {
  &.export-right {
    display: flex;
    justify-content: flex-end;
  }
}
/* -- Pagination -- */
.pager-wrap{
  max-width: 172px;
  width: 100%;
}
.pagination {
  text-align: center;
  a {
    width: auto;
    display: inline-block;
  }
}

.table-scroll {
  tbody {
    tr {
      td {
        min-width:100px;
      }
    }
  }
}