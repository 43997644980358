.off-canvas-content {
    &.is-open-left {
        &.has-transition-push {
            width:calc(100% - #{$offcanvas-size});
        }
    }
}
.full-width-dashboard {
    .main-wrapper {
        &.off-canvas-wrapper {
            display: block;
            aside {
                position: absolute;  
            }
            main {
                width:auto;
                position: relative;
                button.canvas-button {
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    padding: 0px;
                    font-size: 15px;
                    width: 26px;
                    height: 26px;
                    margin: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                }
                &.is-open-left {
                    &.has-transition-push {
                        width:calc(100% - #{$offcanvas-size});
                        // transition: width 5s;
                    }
                }
                .js-off-canvas-overlay.is-overlay-absolute {
                    display: none !important;
                }
            }

        }
    }
}