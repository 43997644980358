a{
  font-size: 1rem;
  font-weight: 600;  
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color:$header-font-color;
}
h2, .h2 {
  margin:0px;
  padding:0px 0 10px 0;
  font-weight:600;
}
h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  font-weight:700;
}
h3, .h3 {
  small {
      display:block;
      &.dashboard { display: inline-block; }
      text-align: center;
      padding:3px 0px;
      line-height: 1;
  }
}
body {
  a {
    color: $font-action;
    font-weight:400;
    text-decoration: underline;
  }
}
a {
  color: $font-action;
  font-weight:400;
  &.print {
    text-align:right;
    line-height: 2.25rem;
    &::before {
      content: "\f02f";
      font-family: "Fontawesome";
      margin-right: 10px;
    }
  }  
}
.report-header {
  position:relative;
  h3, .h3 {
      padding:15px;
  }
  .ab-top-right {
      position: absolute;
      top:0px;
      right:10px;
  }
}
footer {
  p {
      font-size: .875rem;
      margin: 0;
      letter-spacing: 0.125em;
  }
}
.strong {
  font-weight:600;
}


