.visitor-bar {
    background:$white;
    border:1px solid $dark-gray;
    width: 250px;
    padding:15px 0px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    text-align: center;
    p {
        font-style:italic;
    }
    .button {
        margin-bottom: 0px;
    }
    -webkit-box-shadow: 2px 2px 10px 0 $panel-shadow-color;
    box-shadow: 2px 2px 10px 0 $panel-shadow-color;
}