.photo-cards {
    &.slick-initialized .slick-slide {
        padding-left:5px;
        padding-right:5px;
    }    
}
.photo-card {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, .2), 0px 3px 4px 0px rgba(0, 0, 0, .14), 0px 1px 8px 0px rgba(0, 0, 0, .12);
    border-radius: 0px;
    .card-section {
        padding:1rem;
        .title {
            font-size:1rem;
            font-weight:600;
            margin:0px;
            padding:0px;
        }
        p {
            font-size:0.9rem;
        }
        &.photo-form {
            padding:0px;
            form {
                fieldset {
                    padding:0px;
                    margin:0px;
                    legend {
                        display: block;
                        border-bottom:1px solid $panel-border;
                        font-size:1rem;
                        font-weight:600;
                        margin:0px;
                        padding:10px 0px !important;
                        font-family:$body-font-family;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    .fieds-wrapper {
                        padding:1rem;
                    }
                    input[type=text]  {
                        font-size: 1rem;
                        padding: .25rem .75rem;
                        line-height: 1em;
                        height:2rem;
                    }
                    .button-group {
                        .button {
                            width: 100%;
                            max-width: 250px;
                            margin-bottom: 25px;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .photo-actions {
        padding:0.5rem 1rem;
        border-top:1px solid $panel-border;
        display: flex;
        justify-content: space-around;
        .button, button {
            margin:0px;
        }
    }
    .photo {
        width:250px;
        height:250px;
        object-fit: cover;
    }
}
