body{
  position: relative;
  height: 100vh;
  padding-bottom: 7rem;
}
main.grid-container {
  margin-top: 2.5em;
  margin-bottom: 5em;
}
.inline-pager {
  position: relative;
  padding: 10px 0 30px 0;
  .per-page-copy {
      width:100%;
      text-align:left;
      text-indent:-99999px;
      position: relative;
      ul {
          display: inline-block;
          list-style: none;
          margin: 0 auto;
          text-indent: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          width:90%;
          text-align: center;
          li {
              margin:0 5px;
              font-size:0.9rem;
              display:inline;
          }
      }
  }
  .per-page-label {
      position: absolute;
      right:0px;
      top:3px;
      label {
          display: flex;
          font-size: 0.9rem;
          white-space: nowrap;
      }
      select {
          height:2rem;
          font-size:1rem;
          margin-left:10px;
          top:-3px;
          position: relative;
      }
  }
}
//Login
label[for=admin_remember]{
  margin-bottom: 0.5rem;
}
#alerts {
  position: absolute;
  top: 55px;
  right: 0;
  .callout {
    margin: 12px;
  }
}
.no-btm-marg{
  margin-bottom: 0px;
}
.no-btm-pad{
  padding-bottom: 0px;
}
.reveal-overlay{
  z-index: 4005;  
}

// AKN 2021-03-13: Overriding a strange foundation style
// https://github.com/foundation/foundation-sites/blob/258d9eb3432d7e497f41b4554f4116d5c59c92a8/scss/components/_reveal.scss#L136
// Says it is to "prevent the background from shifting" but it actually cuts off everything after 100vh
html.is-reveal-open body { overflow-y: initial !important; }
