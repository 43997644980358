.stats {
    &.stat-display-integer {
        font-size:1rem;
        display: flex;
        flex-direction: column;    
        color:$font-medium;  
        .integer {
            font-size:2.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color:$font-accent;
            a {
                font-size:1em;
            }
        }
        .description {
            display: block;
            width:90%;
            margin:0px auto;
            padding:0px;
            text-align: center;
            color:$font-light;
            a {
                color:$font-light;
                text-decoration: underline;
            }
        }
    }    
}
.state {
    border-bottom:2px solid $panel-sep;            
    &.pass {
        border-bottom-color:$green;
        border-bottom-width:2px;
        .integer {
            color:$green;
            a {
                color:$green; 
            }
        }
    } 
    &.info {
        border-bottom-color:$info-yellow;
        border-bottom-width:2px;
        .integer {
            color:$info-yellow;
            a {
                color:$info-yellow; 
            }
        }
    } 
    &.error {
        border-bottom-color:$error-red;
        border-bottom-width:2px;
        .integer {
            color:$error-red;
            a {
                color:$error-red;
            }
        }
    }                 
}