.pagination {
    .disabled {
        display: none;
        visibility: hidden;
    }
}
.results-count {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin:15px 15px 0 0;
}