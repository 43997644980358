// Certain pages can be printed as pdf by adding .pdf to the url (currently, tactical plans and meetings)
// Add more url patterns to the list of printable pages in config/application.rb

body#as-workplace {
  @media print {
    [aria-hidden="true"] {
      display: none !important;
    }
    .no-print, .no-print * {
      display: none !important;
    }
    .button, .nav-wrap {
      display: none !important;
    }

    header .top-bar {
      display: none !important;
    }

    /* correct size of top and bottom logos */
    #logo {
      max-width: 250px !important;
    }
    main .bottom-branding img {
      max-width: 150px !important;
    }

    /* remove negative margin */
    .main-wrapper > .grid-x {
      margin: 0;
    }
    /* hide left sidebar and make main content area use full width */
    aside {
      display: none !important;
    }
    .main-wrapper > .grid-x > .content {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    /* don't display urls onscreen */
    a[href]:after {
      content: none !important;
    }
  }
  @media screen {
    .print-only, .print-only * {
      display: none !important;
    }
  }
}