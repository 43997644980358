.logged-out {
    background-color:$logout-main-bg;
    header {
        text-align:center;
        padding:30px 0px;
        background-color: $logout-header-bg;
        border:none;
        box-shadow: none;
        .branding {
            h1 {
                margin:0px;
                padding:0px;
                color:$header-font-color;
            }
            img {
                max-width:250px;
                max-height: 70px;
                width: auto !important;
                height: auto !important;    
            }
            &.branding-spacing {
                img {
                    margin-bottom:1.25rem;
                }
            }
        }
    }
    main {
        padding-bottom:100px;
        .grid-container {
            max-width:600px;
            .main-wrapper {
                &.panel {
                    margin-top:0px;
                    margin-bottom:0px;
                }
            }
        }
        .login-form {
            position: relative;
            padding:2rem;
            form {
                width:90%;
                display:block;
                margin:0px auto;
                fieldset {
                    padding:0px;
                    margin:0px;
                }
                input {
                    &[type='submit'] {
                        margin-bottom: 0px;
                    }
                }
            }
            .shared-links{
                padding-top:1.5rem;
                padding-bottom: 0rem;
            }
            .aloha-safe-branding {
                position:absolute;
                right:0px;
                bottom:-70px;
                width:150px;
                img {
                    width:100%;
                    height:auto !important;
                }
            }
        }
    }
    footer {
        display: none;
    }
    &.building-select {
        .grid-container {
            max-width: 90rem;
            .main-wrapper {
                &.panel {
                    background-color: transparent;
                    border:none;
                }
                .building-panels {
                    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
                    .panel-card {
                        position: relative;
                        overflow: hidden;
                        &:hover {
                            -webkit-box-shadow: 2px 2px 10px 0 $panel-shadow-color;
                            box-shadow: 2px 2px 10px 0 $panel-shadow-color;
                            cursor: pointer;
                        }
                        .destination-link {
                            position: absolute;
                            top:-9999px;
                            left:-9999px;
                        }
                    }
                }
            }
        }
    }
}