form {
    fieldset {
        padding:10px 0px;
        margin: 0 0 50px 0;
        legend {
            color:$legend-color;
            font-size:1.35rem;
            border-bottom:1px solid $dark-gray;
            display: block;
            width:100%;
            padding:0px !important;
        }
    }
    label {
        display: block;
        font-size:1rem;
        margin: 0;
        font-weight: 600;
        line-height: 1.2;
        color: $font-medium;
        span {
            &.form-error {
                font-size:0.8rem;
                font-weight:600;
            }
            &.name {
                margin-bottom: 10px;
            }
        }
        + input,
        + select,
        + .date-picker,
        span.name + input,
        span.name + select,
        span.name + textarea,
        span.name + .date-picker {
            margin-top: 10px !important;
        }
        @include breakpoint(small only) {
            border-radius:6px;  
        }       
    }
    .checkbox-row {
        display: flex;
        justify-content: space-between;
        .item {
            padding-right:10px;
        }
        @media (max-width: 820px) {
            display: block;
        }
    }
    input[type='checkbox'] {
        position: relative;
        top:0px;
    }
    input[type="date"] {
        font-size: 1rem;
    }
}
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'] {
    // height:auto;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $field-borders;
    box-shadow: none;
    height: calc(2.75rem + 2px); 
    padding: .65rem .75rem;
    line-height: 1.5;
    border-radius: .2rem;
    font-weight: 400;
}
input::placeholder {
    color:$placeholder-text;
}
textarea {
    height:auto;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $field-borders;
    box-shadow: none; 
    padding: .65rem .75rem;
    line-height: 1.5;
    border-radius: .2rem;
    font-weight: 400;
}    
[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus, textarea:focus {
    outline: none;
    border: 1px solid #23a9e8;
    background-color: $white;
    box-shadow: 0 0 5px #78e6ff;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}   
.fa-bar-chart:before{  
  content: "\f080";
}
.fa-map-marker-alt:before{
    content: "\f3c5";
}
.fa-users:before{
    content: "\f0c0";
}
select{
    font-size: 1rem;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $field-borders;
    box-shadow: none;
    height: calc(2.75rem + 2px); 
    line-height: 1.5;
    border-radius: .2rem;
    font-weight: 400;    
}
.is-invalid-input:not(:focus):not(.visited) {
    border-color: #23a9e8;
    background-color: $white;
}
.is-invalid-label:not(.visited) {
    color: initial;
}
label[for=file]{
    padding: 1rem 0px;
}
.field_with_errors {
    label {
        color:$dark-red;
    }
}
.boxed-results {
    width: 95%;
    margin:25px auto;
    padding:20px;
    border:1px solid $dark-gray;
    -webkit-box-shadow: 2px 2px 10px 0 $panel-shadow-color;
    box-shadow: 2px 2px 10px 0 $panel-shadow-color;
    .flex-header {
        display:flex;
        justify-content: space-between;
        align-items: center;
        h3 {
            margin:0px;        
        }
        .button {
            margin:0px;
        }
    }
}

// add calendar icon to native date pickers
input[type=date] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    position: relative;
    &::before {
        content: '\f073';
        font-family: 'Font Awesome\ 5 Free';
        text-align: right;
        font-size: 1.3em;
        position: absolute;
        right: 16px;
        top: 6px;
        pointer-events: none;
        background: white;
    }
}
input[type=time] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    position: relative;
    &::before {
        content: '\f017';
        font-family: 'Font Awesome\ 5 Free';
        text-align: right;
        font-size: 1.1em;
        position: absolute;
        right: 16px;
        top: 6px;
        pointer-events: none;
        background: white;
    }
}