footer {
    background: $footer-background;
    color: $white;
    min-height:22px;
    padding: 14px 0;
    @include breakpoint(medium down) {
        > .grid-container > div.grid-x > .cell {
           text-align: center;
        } 
    }
}