.button {
  font-family: $body-font-family;
  font-weight:500;
  line-height: 1.7em;
  padding: .3rem 1rem;
  margin-bottom: 26px;
}
.button.reset-pass{
    margin-top: 1rem;
}
body {
    a {
        &.button {
            text-decoration: none;
        }
    }  
}
table {
    td {
        .button {
            margin:0px;
        }
    }
}
.btn-wrap {
    .button {
        margin-bottom:0px;
    }
}
.results-filters {
    .field{ 
        input.button, button {
            font-size: 18px;
            border-radius: 0.25rem;
            font-weight:500;
            min-width: 100px;
            text-align: center;
        }
    }
}
#new_user{
    .actions{        
        input:not(.button){
            font-size: 1rem;
            padding-left: 0px;
            color:#ff7227;            
            background: none;            
            border: none;            
            font-family: "Open Sans", sans-serif;            
            font-weight: 600;            
        }
    }
}
.dropdown {
    &.menu {
        li {
            a {
                &.button {
                    font-weight:400;
                }
            }
            &.is-active {                
                a {
                    &.button {
                        background-color: $nav-button-hover;
                        color:$white;
                        &::after {
                            // border-color:$white;
                            border-color: $white transparent transparent;
                        }
                    }
                }
            }
        }
    }
}
.top-bar {
    ul {
        li {
            a {
                &.button {
                    font-weight:400;                
                }
            }
            &.is-active {
                a {
                    &.button {
                        background-color: transparent;
                        color:$nav-accent-color;
                    }
                }
            }            
        }
    }
    .dropdown {
        &.menu {
            li {
                a {
                    &.button {
                        &::after {
                            border-color: $nav-accent-color transparent transparent;
                        }
                    }
                }
                &.is-active {                
                    a {
                        &.button {
                            background-color:transparent;
                            color:$nav-accent-color;
                            &::after {
                                border-color: $nav-accent-color transparent transparent;
                            }
                        }
                    }
                }
            }            
        }
    }   
}
.jump-menu {
    .dropdown {
        &.menu {
            li {
                &.is-active {                
                    a {
                        &.button {
                            color:$white;
                        }
                    }
                }
            }
        }
    }
}

.panel-title {
    .button {
        margin: 0 0 1rem 0;
        &.hollow {
            background-color: $white;
        }
    }
    .button-group {
        justify-content:flex-end;
        .button {
            margin-left:10px;
            @media (max-width: 864px) {
                margin-bottom: 14px;
                // &:last-child {
                //     margin-bottom: 0;
                // }
            }
        }
    }
    .button {
        margin: 0;
    }

} 
.panel-body {
    &.has-tabs {
        .panel-tabs {
            .tab-buttons{
                .button {
                    border-radius: 0.25rem;
                    margin-right:0px;
                    margin-bottom: 0px;
                }
            }           
        }
    }  
}
.panel-bottom {
    .button {
        margin:0px;
    }
}